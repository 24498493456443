import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Polly } from '../../audioPolly/Polly'
import { Language} from '../../language/Language'
import { getCurrentBook, getSingleCurrentBookSpecificVocabulary, getLanguage1, getLanguage2} from '../../data/Data'

import './ListenToVocabulary.css'


class ListenToVocabulary extends React.Component {
    constructor(props) {
        super(props);
        let loadData = getCurrentBook()
        this.state = {
            data: loadData,
            audioIsRunning: false
        }
        this.Polly = new Polly()
        this.englishGerman = false;
        this.startStopButton = Language('Start')
        this.selectOutput = React.createRef();
        this.selectLanguageOrder = React.createRef();
        this.optionAudioPlayrate = [0.8, 0.9, 1, 1.1, 1.2]
        this.ButtonText ={
            VOICE1VOICE2: Language(getLanguage1()) +' -> '+ Language(getLanguage2()),
            VOICE2VOICE1: Language(getLanguage2()) +' -> '+ Language(getLanguage1()),
    }
    }


    getIsAudioRunning() {
        return this.state.audioIsRunning
    }

    audioIsDone() {
        this.startStopButton = Language('Start')
        this.setState({ audioIsRunning: false })
    }

    startStopListenToVocabulary() {
        let languageOderDict = {
            'en': {
                'data': 0,
                'voiceId': this.Polly.currentVoice1,
            },
            'ger': {
                'data': 1,
                'voiceId': this.Polly.currentVoice2,
            },
        }
        let data = getSingleCurrentBookSpecificVocabulary(this.selectOutput.current.value)
        let selectLanguageOder = this.selectLanguageOrder.current.value
        let voice1params = languageOderDict[selectLanguageOder.split('-')[0]]
        let voice2params = languageOderDict[selectLanguageOder.split('-')[1]]

        this.startStopButton = Language('Stop')
        if (!this.state.audioIsRunning) {
            this.setState({ audioIsRunning: true }, () => {
                this.Polly.sayData(data, voice1params, voice2params, () => this.getIsAudioRunning(), () => this.audioIsDone())
            })
        }
        else {
            this.setState({ audioIsRunning: false })
        }
    }

    changeAudioPlayrate(rate) {
        this.Polly.setCurrentAudioPlaybackRate(rate)
    }

    render() {
        return (
            <div className="wrapper">
                {this.isDone ? <h1> {Language('!! Finished !!')}</h1> :
                    <div>
                        <Form>
                            <Form.Group controlId="translatedText"  >
                                <Button className="m-1 mt-2" variant="outline-primary unmarked" style={{ font: "20" }} onClick={this.startStopListenToVocabulary.bind(this)}
                                    onMouseDown={(e) => e.preventDefault()}>{this.startStopButton}</Button>
                            </Form.Group>
                        </Form>
                        <Form.Group className='listenformgroup' >
                            <Form.Control  style={{width:'60%'}} as="select" ref={this.selectLanguageOrder}>
                                <option value='ger-en'>{this.ButtonText.VOICE2VOICE1}</option>
                                <option value='en-ger'>{this.ButtonText.VOICE1VOICE2}</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Label>{Language('Select output to listen')}</Form.Label>
                        <Form.Group className='listenformgroup'>
                            <Form.Control style={{width:'60%'}} as="select" ref={this.selectOutput}>
                                {this.state.data.content.map((data, index) => (
                                    <option key={index} value={data.dataName}>{data.dataName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Label>{Language('Change the audio speed')}</Form.Label>
                        <Form.Group className='listenformgroup'>
                            <Form.Control style={{width:'60%'}} as="select" defaultValue={1} onChange={change => this.changeAudioPlayrate(change.target.value)}>
                                {this.optionAudioPlayrate.map((rate, index) => (
                                    <option key={index} value={rate}>{'x' + rate}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                }
            </div>
        )
    }
}

export default ListenToVocabulary;