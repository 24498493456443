import React from 'react';
import SelectBookOption from '../selectBookOption/SelectBookOption'
import {getCurrentBookName} from '../../data/Data'



export default function SelectBook() {
        return (
            <div className="wrapper">
                <h2 className='mt-2'>{getCurrentBookName}</h2>
                <SelectBookOption/>
            </div>

        )
}



