import React from 'react';
import Button from 'react-bootstrap/Button';

import { Polly } from '../../audioPolly/Polly'
import { TranslateService } from '../../translateService/TranslateService'
import { Language } from '../../language/Language'
import Form from 'react-bootstrap/Form';
import Loader from "react-loader-spinner";


class TranslateAndSpeak extends React.Component {
    constructor(props) {
        super(props)
        this.inputField = ''
        this.Polly = new Polly();
        this.translateService = new TranslateService();
        this.ButtonStatus = { START: Language('translate in 17 languages and play audio'), STOP: Language('Stop') }
        this.state = {
            textVoicesArray: [],
            voices: [],
            languageCodeLanguageNameDict: [],
            selectLanguageCode: 'en',
            audioIsRunning: false,
            currentText: '',
            currentLanguage: '',
            isLoading: true,
            selectedVoice: '',
            startStopButton: this.ButtonStatus.START

        }
        this.inputText = ''

    }



    createTextLanguageCodeIDArray(voices) {
        this.includeTranslation(this.removeMultipleLanguage(this.shuffleArray(this.mapVoices(voices))))
    }

    mapVoices(voices) {
        let voicesLanguageCodeIdArray = voices.map(voice => [voice.LanguageCode.substring(0, 2), voice.Id, voice.LanguageName])
        return voicesLanguageCodeIdArray
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * i)
            const temp = array[i]
            array[i] = array[j]
            array[j] = temp
        }
        return array
    }

    removeMultipleLanguage(voicesLanguageCodeIdArray) {
        let unique = []
        let found = ['nb', 'cm']
        for (let i = 0; i < voicesLanguageCodeIdArray.length; i++) {
            if (!found.includes(voicesLanguageCodeIdArray[i][0])) {
                found.push(voicesLanguageCodeIdArray[i][0])
                unique.push(voicesLanguageCodeIdArray[i])
            }
        }
        return unique
    }


    includeTranslation(voicesLanguageCodeIdArray) {
        let successFullTranslated = (translation, ended) => {
            if (ended) {
                this.setState({
                    isLoading: false
                })

            }
            this.setState({
                textVoicesArray: this.state.textVoicesArray.concat([translation]),
            })
        }
        let multipleTranslationEnded = false
        for (let i = 0; i < voicesLanguageCodeIdArray.length; i++) {
            if (i === voicesLanguageCodeIdArray.length - 1) { multipleTranslationEnded = true }
            this.translateService.doTranslate(this.inputText, this.state.selectLanguageCode, voicesLanguageCodeIdArray[i], successFullTranslated, multipleTranslationEnded)
        }
    }

    componentDidMount() {
        let success = (voices) => {
            this.setState({ voices: voices }, () => this.setState({ isLoading: false }))
        }
        this.Polly.getAllVoices(success)
        let saveLangDict = (langDict) => {
            this.setState({ languageCodeLanguageNameDict: langDict }, () => { this.setState({ isLoading: false }) })
        }
        this.Polly.getLanguageCodeLanguageNameDict(saveLangDict)

    }

    getIsAudioRunning() {
        return this.state.audioIsRunning
    }

    audioIsDone() {
        this.setState({ startStopButton: this.ButtonStatus.START })
        this.setState({ audioIsRunning: false, currentText: '', currentLanguage: '', textVoicesArray: [] })
    }

    changeCurrentText(currentText) {
        this.setState({
            currentText: currentText[0],
            currentLanguage: "(" + currentText[1] + ", " + currentText[2] + ")"
        })
    }

    timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    async startStopListenToVocabulary() {
        if (!this.state.audioIsRunning && this.inputText !== '') {
            this.setState({ startStopButton: this.ButtonStatus.STOP })
            this.setState({ isLoading: true }, () => this.createTextLanguageCodeIDArray(this.state.voices))
            this.Polly.say('Start', 'Joanna')
            await this.timeout(1000)
            this.setState({ audioIsRunning: true, isLoading: false })
            this.Polly.sayCollection(this.state.textVoicesArray, () => this.getIsAudioRunning(), () => this.audioIsDone(), (text) => this.changeCurrentText(text))

        }
        else {
            this.setState({ startStopButton: this.ButtonStatus.START })
            this.setState({ audioIsRunning: false, currentText: '', currentLanguage: '', textVoicesArray: [], isLoading: true }, () => { setTimeout(() => { this.setState({ isLoading: false }) }, 5000) })
        }
    }

    handleChange(event) {
        this.inputText = event.target.value
    }

    changeSelectedLanguageCoe(code) {
        this.setState({ selectLanguageCode: code })
    }

    displayVoiceLanguageName(voice) {
        let splitVoice = voice.split(' ')
        if (splitVoice.length > 1) {
            return splitVoice[0] + ' ' + Language(splitVoice[1])
        }
        return Language(voice)
    }


    render() {
        return (
            <div className="wrapper">
                {this.state.isLoading ?
                    <Loader className="m-2"
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                    :
                    <div>
                        <Button className="m-1 mt-2 mb-1" variant="outline-primary unmarked" style={{ font: "20" }} onClick={this.startStopListenToVocabulary.bind(this)}
                            onMouseDown={(e) => e.preventDefault()}>{this.state.startStopButton}</Button>
                        <h2>{this.state.currentText}</h2>
                        <h5>{this.state.currentLanguage}</h5>
                        {this.state.startStopButton === this.ButtonStatus.START ? <Form>
                            <Form.Group className="mt-1" controlId="translatedText"  >
                                <Form.Control style={{ fontSize: "1.5em", textAlign: "center" }} as="textarea" placeholder='...' maxLength="72" autoFocus onChange={this.handleChange.bind(this)}
                                    autoCapitalize='none'
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </Form.Group>
                            <Form.Label>{Language('Select initial language')}</Form.Label>
                            <Form.Group className='listenformgroup text-center'>
                                <Form.Control className='w-50 mb-7"  text-center' as="select" value={this.state.selectLanguageCode} onChange={(event) => this.changeSelectedLanguageCoe(event.target.value)}>
                                    {this.state.languageCodeLanguageNameDict.map((data) => (
                                        <option key={data[0]} value={data[0]}>{data[1]}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form> : ''}
                    </div>
                }
            </div>
        )
    }
}

export default TranslateAndSpeak;