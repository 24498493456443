import React from 'react';
import './index.css';
import App from './App';
import { render } from 'react-dom'



render(
  <div>
    <App />
  </div>,
  document.getElementById('root')
)
