import React from 'react';
import PayPalButton from '../paypalbutton/ButtonPayPal'
import { Language } from '../../language/Language'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'


export class ProjectSupport extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            supportAmount: 5
        }
    }

    handleChange(event) {
        if (event.target.value > 0) this.setState({ supportAmount: event.target.value })
    }
    render() {
        return (
            <Card >
                <Card.Header>{Language('Support ReTraMa')}</Card.Header>
                <Card.Body className='w-100'>
                    <Form.Group as={Row} className='w-50 mx-auto'>
                        <Form.Control className='mb-2 w-50' style={{ fontSize: "1.5em", textAlign: "center" }} type="number" defaultValue='5' min='1' autoFocus onChange={this.handleChange.bind(this)} />
                        <Form.Control className='mb-2 w-50' style={{ fontSize: "1.5em", textAlign: "center" }} type="text" placeholder='EUR' disabled />
                    </Form.Group>
                    <PayPalButton amount={this.state.supportAmount} />
                </Card.Body>
            </Card>
        )
    }
}

