import React from 'react';
import Form from 'react-bootstrap/Form';

import {getCurrentBookName, getAllBookNames, loadBook} from '../../data/Data'
import {Language} from '../../language/Language'


class SelectBookOption extends React.Component {
    constructor(props) {
        super(props);
        this.selectedBook = getCurrentBookName
    }

    changeBook(e){
        this.selectedBook=e.target.value
        loadBook(e.target.value)
        sessionStorage.removeItem('dataSelected')
        sessionStorage.removeItem('dataNamesSelected')
        sessionStorage.removeItem('Voice1')
        sessionStorage.removeItem('Voice2')
        sessionStorage.setItem('currentPosition', Language('Home'))
        setTimeout(()=>{window.location.href='/'},500)
        
    }


    render() {
        return (
                <Form.Group className='listenformgroup'>
                            <Form.Control className='w-75'value={this.selectedBook} as="select"  onChange={(e)=>this.changeBook(e)}>
                                {getAllBookNames().map((bookName, index) => (
                                    <option key={index} value={bookName}>{bookName}</option>
                                ))}
                            </Form.Control>
                </Form.Group>

        )
    }
}

export default SelectBookOption;