import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Polly } from '../../audioPolly/Polly'
import { Language } from '../../language/Language'


class LetPollySpeak extends React.Component {
    constructor(props) {
        super(props)
        this.inputText = ''
        this.Polly = new Polly();

        this.state = {
            voices: [],
            selectedVoice: this.Polly.currentVoice1
        }

    }

    resortLanguageName(a) {
        return a.LanguageName.split(' ').length > 1 ? a.LanguageName.split(' ')[1] + a.LanguageName.split(' ')[0] : a.LanguageName
    }

    sorting(a, b) {
        return this.resortLanguageName(a) > this.resortLanguageName(b)
    }

    tranlateVoices(voices) {
        for (let i = 0; i < voices.length; i++) {
            voices[i].LanguageName = this.displayVoiceLanguageName(voices[i].LanguageName)

        }
    }

    componentDidMount() {
        let success = (voices) => {
            this.tranlateVoices(voices)
            voices.sort((a, b) => this.sorting(a, b))
            this.setState({
                voices: voices
            })
        }
        this.Polly.getAllVoices(success)
    }

    handleChange(event) {
        this.inputText = event.target.value
    }

    handleClearClick() {
        this.inputText = ''
    }

    handleSpeakClick() {
        console.log(this.inputText)
        if (this.inputText.length > 0) {
            this.Polly.say(this.inputText, this.state.selectedVoice)
        }
        else {
            this.Polly.say(Language('type what i should say'), this.state.selectedVoice)
        }
    }

    changeVoice(voice) {
        this.setState({ selectedVoice: voice })
    }

    displayVoiceLanguageName(voice) {
        let splitVoice = voice.split(' ')
        if (splitVoice.length > 1) {
            return splitVoice[0] + ' ' + Language(splitVoice[1])
        }
        return Language(voice)
    }


    render() {
        return (
            <div className="wrapper">
                <Form>
                    <Form.Group controlId="translatedText"  >
                        <Button className="m-2" variant="outline-primary unmarked" style={{ font: "20" }} onClick={this.handleSpeakClick.bind(this)}
                            onMouseDown={(e) => e.preventDefault()}>{Language('Speak')}</Button>
                        <Button className="m-2" variant="outline-primary unmarked" style={{ font: "20" }} type="reset" onClick={this.handleClearClick.bind(this)}
                            onMouseDown={(e) => e.preventDefault()}>{Language('Clear')}</Button>
                        <Form.Control style={{ fontSize: "1.5em", textAlign: "center" }} as="textarea" placeholder='...' maxLength="48" autoFocus onChange={this.handleChange.bind(this)}
                            autoCapitalize='none'
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </Form.Group>
                    <Form.Group className='listenformgroup text-center'>
                        <Form.Control className='text-center' as="select" value={this.state.selectedVoice} onChange={(event) => this.changeVoice(event.target.value)}>
                            {this.state.voices.map((data, index) => (
                                <option key={index} value={data.Id}>{data.Id} - {data.LanguageName}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

export default LetPollySpeak;