const dictonary = [
    ['Translate into ', 'Übersetzen ins '],
    ['Listen and Write', 'Hören und Schreiben'],
    ['Select Vocabulary', 'Vokabeln auswählen'],
    ['right Words: ', 'richtige Wörter: '],
    ['typing Errors: ', 'Tippfehler: '],
    ['used Helps: ', 'Hilfe benutzt: '],
    ['Restart', 'Erneut starten'],
    ['Help', 'Hilfe'],
    ['!! Finished !!', '!! Gechafft !!'],
    ['Replay', 'Erneut abspielen'],
    ['Start', 'Starten'],
    ['listen and type', 'hören & schreiben'],
    ['Wrong', 'Falsch'],
    ['Done, ', 'Geschafft, '],
    [' mistakes.', ' Fehler.'],
    ['Questions: ', 'Fragen: ',],
    ['please translate', 'bitte übersetzen'],
    ['Deselect All', 'Alle abwählen'],
    ['Select All', 'Alle auswählen'],
    ['Select Application Language', 'Anwendungssprache ändern'],
    ['Quiz', 'Quiz'],
    ['Settings', 'Einstellungen'],
    ['Typing', 'Schreiben'],
    ['Special', 'Zusatz'],
    ['let Polly speak', 'lass Polly sprechen'],
    ['Speak', 'Spreche'],
    ['Clear', 'Text löschen'],
    ['type what i should say', 'was soll ich sagen'],
    ['Choose Audio Voices', 'Stimmen ändern'],
    ['Listen to vocabulary', 'Höre das Vokabular'],
    ['Stop', 'Stoppen'],
    ['Select output to listen', 'Wähle was du hören möchtest'],
    ['Please select a data source otherwise the latest deselected data source will remain',
        'Bitte wähle mindestens einen Vokabelsatz aus, ansonsten bleiben die letzten Vokabeln ausgewählt'],
    ['Change the audio speed', 'Ändere die Sprachgeschwindigkeit'],
    ['Choose english voice', 'Wähle die englische Stimme'],
    ['Choose german voice', 'Wähle die deutsche Stimme'],
    ['Select a Book', 'Wähle ein Buch'],
    ['Choose the', 'Wähle die'],
    ['voice', 'Stimme'],
    ['Translate and Speak', 'Übersetzen und Sprechen'],
    ['Select initial language', 'Ausgangssprache wählen'],
    ['translate in 17 languages and play audio', 'übersetzen in 17 Sprachen und Audio abspielen'],
    ['Welcome to ReTraMa, this page was designed to help you to improve your language skills through Quizes, Translation, Hearing and Writing. Right now its absolutely free.',
        'Willkommen auf ReTraMa, diese Seite wurde entworfen um dir dabei zu helfen deine Sprachfertigkeiten durch Quizes, Übersetzungen, das Hören und Schreiben, zu verbessern. Aktuell ist die Nutzung komplett kostenlos.'],
    ['In this section you can try out your vocabulary knowledge by doing a German to English or English to German Quiz.',
        'Hier kannst du dein Vokabelwissen durch ein Deutsch - Englisch oder Englisch - Deutsch Quiz testen.'],
    ['In this section you have to translate the given input. The first option gives you the plain german text as input. The second option speaks the english text you have to write. The third option gives you the german text and speaks the english solution.',
        'In diesem Abschnitt sollst du selber übersetzen. In der ersten Option bekomst du den englischen Text und musst diesen übersetzen. In der zweiten Option wird dir der englsche Text vorgesprochen. In der dritten Option wird bekommst du den deutschen Text und die englische Lösung angesagt.'],
    ['Listen to selected vocabulary', 'Höre ausgewähltes Vokabular'],
    ['In this section you can choose selected vocabulary and listen to the translation.',
        'Hier kannst du dir spezielles Vokabular aussuchen und dir die Übersetzung anhören'],
    ['Special Feature', 'Zusatzfunktionen'],
    ['In the section I provide cool features. You can let polly speak what you want and choose her voice. Another feature is that you can put in text in one of 18 languages and get the translation and the speach output in these languages',
        'In diesem Bereich findest du zusätzliche coole Sachen. Du kannst Polly sprechen lassen was du willst und sogar ihre Stimme wählten. Als Zweites kannst du einen Text in einer aus 18 Sprachen eingeben und diese wird in die anderen Sprachen übersetzt und ausgesprochen.'],
    ['Restart', 'Erneut starten'],
    ['Apply', 'Übernehmen'],
    ['Return to ', 'Zurück zu '],
    ['Support ReTraMa through Paypal', 'Helfen Sie ReTraMa mittels Paypal'],
    ['Support ReTraMa', 'Unterstütze ReTraMa'],
    ['If you want to support this project you can help me with your Feedback or financially through Paypal', 'Wenn Ihnen das Projekt gefällt können Sie mich gerne mit Ihrem Feedback und über Paypal unterstützen'],
    ['Thank you for your support!', 'Vielen Dank für die Unterstüzung'],
    ['Imprint', 'Impressum'],
    ['Report spelling error', 'Tippfehler melden'],
    ['Cancel', 'Abbrechen'],
    ['Please tell me what you think is wrong.', 'Bitte schreibe hier was falsch ist.'],
    ['Error on', 'Fehler bei'],
    ['Send Feedback', 'Sende Feedback'],
    ['Send Feedback via Email', 'Sende Feedback per Email'],
    ['Home', 'Startseite'],
    ['Translate into Spanish', 'Übersetzen ins Spanische'],
    ['Listen with Spanish text', 'Hören mit spanischem Text'],
    ['Listen with German text', 'Hören mit deutschem Text'],
    ['Currently selected Book', 'Aktuell ausgewähltes Buch'],
    ['Select your book', 'Wähle ein Buch aus'],
    ['Select Book another book', 'Wähle ein anderes Buch'],
    ['Current Book: ', 'Gewähltes Buch: '],
    [' text', ' Text'],
    ['Listen with text in ', 'Hören mit Text auf '],
    ["Arabic", 'Arabisch'],
    ["Danish", 'Dänisch'],
    ["Dutch", 'Niederländish'],
    ["English", 'Englisch'],
    ["French", "Französich"],
    ["German", "Deutsch"],
    ["Icelandic", "Isländisch"],
    ["Italian", "Italienisch"],
    ["Japanese", "Japanisch"],
    ["Korean", "Koreanisch"],
    ["Polish", "Polnisch"],
    ["Portuguese", "Portugisisch"],
    ["Romanian", "Rumenisch"],
    ["Russian", "Russisch"],
    ["Spanish", "Spanisch"],
    ["Swedish", "Schwedisch"],
    ["Turkish", "Türkisch"],
    ["Welsh", "Walisisch"],
    ['Mandarin', "Mandarin"],
    ['Norwegian', "Norwegisch"],
    ['Wrong Answers: ', 'Falsche Antworten: '],
    ['Questions answered: ', 'Fragen beantwortet: ']
]

const languageKey = new Map([
    ['en', 0],
    ['de', 1]
])

const defaultLanguage = () => {
    if (window.location.href.split('/')[2].split('.')[2] === 'de') return 'de'
    return 'en'
}

var language = sessionStorage.getItem('currentLanguage') ? JSON.parse(sessionStorage.getItem('currentLanguage')) : defaultLanguage()

export const Language = (phrase) => {
    let translationFound = dictonary.filter(vocabulary => vocabulary[0] === phrase)
    if (translationFound.length > 0) {
        return translationFound[0][languageKey.get(language)]
    }
    console.log('Error on translating ' + phrase)
    return phrase
}

