
import AWS from 'aws-sdk'

AWS.config.region = 'eu-central-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL
});



export default class DataHandler {
  constructor() {
    this.docClient = new AWS.DynamoDB.DocumentClient()
  }

  getSpecificBook(bookName, success) {
    var params = {
      Key: {
        bookName: bookName
    },
      TableName: 'ReTraMa'
    };
    this.docClient.get(params, (error, data) => {
      if (error) {
        console.log('error', error);
      } else {
        success(data.Item)
      }
    })}



    getAllBookNames(success) {
      var params = {
        TableName : 'ReTraMa',
        AttributesToGet: ['bookName'], 
        Select: 'SPECIFIC_ATTRIBUTES',
       
      };
      this.docClient.scan(params, (error, data) => {
        if (error) {
          console.log('error', error);
        } else {
          success(data.Items.map(book => book.bookName))
        }
      });

  }



}