import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Language} from '../../language/Language'
import { getCurrentBookPollyLanguageCodes, getLanguage1, getLanguage2} from '../../data/Data'

import { Polly } from '../../audioPolly/Polly'


class SelectAudioVoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voices: []
        }
        this.Polly = new Polly()
        this.selectedVoice = this.Polly.params.VoiceId
        this.showVoice1Options = false
        this.showVoice2Options = false
        this.voice1Text= Language('Choose the') +' '+ Language(getLanguage1())  + ' ' + Language('voice')
        this.voice2Text= Language('Choose the') +' '+ Language(getLanguage2()) + ' ' + Language('voice')
    }

    componentDidMount() {
        let success = (voices) => {
            this.setState({
                voices: voices
            })
        }
        this.Polly.getAllVoices(success)
    }

    filterVoices(data, voiceCode) {
        return data.filter(voice => (voice.LanguageCode.substring(0, voiceCode.length) === voiceCode) && (voice.SupportedEngines.includes('standard')))
    }

    changeVoice1(selectedVoice) {
        this.selectedVoice = selectedVoice.Id
        this.Polly.setVoice1(selectedVoice.Id)
        this.Polly.sayGreeting(selectedVoice)
        this.forceUpdate()
    }

    changeVoice2(selectedVoice) {
        this.selectedVoice = selectedVoice.Id
        this.Polly.setVoice2(selectedVoice.Id)
        this.Polly.sayGreeting(selectedVoice)
        this.forceUpdate()
    }

    changeShowVoice1Options() {
        this.showVoice1Options = !this.showVoice1Options
        this.forceUpdate()
    }
    changeShowVoice2Options() {
        this.showVoice2Options = !this.showVoice2Options
        this.forceUpdate()
    }

    displayVoiceLanguageName(voice){
        let splitVoice = voice.split(' ')
        if (splitVoice.length>1){
            return splitVoice[0] + ' ' + Language(splitVoice[1])
        }
        return Language(voice)
    }

    showVoiceSelector(voices, selectedVoice, changeVoice) {
        return (<Form>
            {voices.map((voice) => (
                <div key={voice.Id} className="mb-2  ml-2 mr-2 list-group-item d-flex justify-content-between">
                    <Form.Check
                        type='radio'
                        id={voice.Id}
                        label={voice.Id + ' (' + this.displayVoiceLanguageName(voice.LanguageName) + ')'}
                        checked={voice.Id === selectedVoice}
                        onChange={() => changeVoice(voice)}
                    />
                </div>
            ))}
        </Form>)
    }

    voice1Text= Language('Choose english voice')

    render() {
        return (
            <div className="wrapper">
                <Button className="m-2 w-75" variant="outline-primary unmarked" style={{ font: "20" }} onClick={() => this.changeShowVoice1Options()}
                    onMouseDown={(e) => e.preventDefault()}>{this.voice1Text}</Button>
                {this.showVoice1Options ? this.showVoiceSelector(this.filterVoices(this.state.voices, getCurrentBookPollyLanguageCodes[0]), this.Polly.currentVoice1, (voice) => this.changeVoice1(voice)) : ''}
                <Button className="m-2 w-75" variant="outline-primary unmarked" style={{ font: "20" }} onClick={() => this.changeShowVoice2Options()}
                    onMouseDown={(e) => e.preventDefault()}>{this.voice2Text}</Button>
                {this.showVoice2Options ? this.showVoiceSelector(this.filterVoices(this.state.voices, getCurrentBookPollyLanguageCodes[1]), this.Polly.currentVoice2, (voice) => this.changeVoice2(voice)) : ''}
            </div>
        )
    }
}

export default SelectAudioVoice;