import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'

import Quiz from './components/quiz/Quiz'
import TypingChallenge from './components/typingChallenge/TypingChallenge'
import ListeningChallenge from './components/listeningChallenge/ListeningChallenge'
import SelectVocabulary from './components/selectVocabulary/SelectVocabulary'
import LetPollySpeak from './components/letPollySpeak/LetPollySpeak'
import SelectAudioVoice from './components/selectAudioVoice/SelectAudioVoice'
import ListenToVocabulary from './components/listenToVocabulary/ListenToVocabulary'
import SelectBook from './components/selectBook/SelectBook'
import TranslateAndSpeak from './components/translateAndSpeak/TranslateAndSpeak';
import Home from './components/home/home';
import {loadBookNames, loadBook, getLanguage1, getLanguage2} from './data/Data'
import { ProjectSupport } from './components/projectSupport/ProjektSupport';
import { Language} from './language/Language'

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import './App.css';
import logo from './logo/logo.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Imprint from './components/imprint/Imprint';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.currentPosition = sessionStorage.getItem('currentPosition') ? sessionStorage.getItem('currentPosition') : Language('Home')
    this.NavBarText ={
            VOICE1VOICE2: Language(getLanguage1())+' -> '+ Language(getLanguage2()),
            VOICE2VOICE1: Language(getLanguage2()) +' -> '+ Language(getLanguage1()),
    }
  
  }

  selectLanguage(lang) {
    this.language = lang
    sessionStorage.setItem('currentLanguage', JSON.stringify(lang))
    sessionStorage.setItem('currentPosition', this.currentPosition)
    window.location.reload(true)
  }

  changeNavWhere(position) {
    sessionStorage.setItem('currentPosition', position)
  }



  componentDidMount(){
    loadBookNames()
    if (!JSON.parse(sessionStorage.getItem('currentBook'))) loadBook('English German Class 5')
  }


  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <Navbar expand="true" >
              <a href='/'><Navbar.Brand onClick={() => this.changeNavWhere(Language('Home'))} ><Image src={logo} alt="ReTraMa" /></Navbar.Brand></a>
              <Navbar.Text> {this.currentPosition}</Navbar.Text>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto mt-5">
                  <NavDropdown title={Language('Quiz')} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/quiz1" onClick={() => this.changeNavWhere('Quiz '+this.NavBarText.VOICE1VOICE2)} >{'Quiz '+this.NavBarText.VOICE1VOICE2}</NavDropdown.Item>
                    <NavDropdown.Item href="/quiz2" onClick={() => this.changeNavWhere('Quiz '+ this.NavBarText.VOICE2VOICE1)} >{'Quiz '+ this.NavBarText.VOICE2VOICE1}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Divider />
                  <NavDropdown title={Language('Typing')} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/typingChallenge" onClick={() => this.changeNavWhere(Language('Translate into ')+ Language(getLanguage1()))} >{Language('Translate into ')+ Language(getLanguage1())}</NavDropdown.Item>
                    <NavDropdown.Item href="/listeningChallenge" onClick={() => this.changeNavWhere(Language('Listen and Write'))} >{Language('Listen and Write')}</NavDropdown.Item>
                    <NavDropdown.Item href="/listeningChallengeWithGerman" onClick={() => this.changeNavWhere(Language('Listen with text in ')+Language(getLanguage2()))} >{Language('Listen with text in ')+Language(getLanguage2())}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Divider />
                  <Nav.Link href="/listenVocabulay" onClick={() => this.changeNavWhere(Language('Listen to vocabulary'))} >{Language('Listen to vocabulary')}</Nav.Link>
                  <NavDropdown.Divider />
                  <NavDropdown title={Language('Special')} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/letPollySpeak" onClick={() => this.changeNavWhere(Language('let Polly speak'))} >{Language('let Polly speak')}</NavDropdown.Item>
                    <NavDropdown.Item href="/translateAndSpeak" onClick={() => this.changeNavWhere(Language('Translate and Speak'))} >{Language('Translate and Speak')}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Divider />
                  <NavDropdown title={Language('Settings')} id="basic-nav-dropdown">
                    <NavDropdown title={Language('Select Vocabulary')} id="basic-nav-dropdown">
                      <NavDropdown.Item href="/selectBook" onClick={() => this.changeNavWhere(Language('Select a Book'))} >{Language('Select a Book')}</NavDropdown.Item>
                      <NavDropdown.Item href="/selectVocabulary" onClick={() => this.changeNavWhere(Language('Select Vocabulary'))} >{Language('Select Vocabulary')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/selectAudioVoice" onClick={() => this.changeNavWhere(Language('Choose Audio Voices'))} >{Language('Choose Audio Voices')}</Nav.Link>
                    <NavDropdown title={Language('Select Application Language')} id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={() => this.selectLanguage('en')}>English</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => this.selectLanguage('de')}>Deutsch</NavDropdown.Item>
                    </NavDropdown>
                  </NavDropdown>
                  <NavDropdown.Divider />
                  <Nav.Link href="/projectSupport" onClick={() => this.changeNavWhere(Language('Support the project'))} >{Language('Support ReTraMa')}</Nav.Link>
                  <NavDropdown.Divider />
                  <Nav.Link href="/imprint" onClick={() => this.changeNavWhere(Language('Imprint'))} >{Language('Imprint')}</Nav.Link>
                  <NavDropdown.Divider />
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Switch>
              <Route path="/typingChallenge">
                <TypingChallenge />
              </Route>
              <Route path="/listeningChallenge">
                <ListeningChallenge withGerman={false} />
              </Route>
              <Route path="/listeningChallengeWithGerman">
                <ListeningChallenge withGerman={true} />
              </Route>
              <Route path="/selectVocabulary">
                <SelectVocabulary />
              </Route>
              <Route path="/selectBook">
                <SelectBook />
              </Route>
              <Route path="/quiz1">
                <Quiz language1to2={true} />
              </Route>
              <Route path="/quiz2">
                <Quiz language1to2={false} />
              </Route>
              <Route path="/listenVocabulay">
                <ListenToVocabulary />
              </Route>
              <Route path="/letPollySpeak">
                <LetPollySpeak />
              </Route>
              <Route path="/translateAndSpeak">
                <TranslateAndSpeak />
              </Route>
              <Route path="/selectAudioVoice">
                <SelectAudioVoice />
              </Route>
              <Route path="/projectSupport">
                <ProjectSupport />
              </Route>
              <Route path="/imprint">
                <Imprint />
              </Route>
              <Route path="/">
                <Home/>
              </Route>
            </Switch>
          </div>
        </Router>

      </div>
    );
  }
}

export default App;
