import { PayPalButton } from "react-paypal-button-v2";
import React from 'react'
import { Language } from "../../language/Language";

export default class ButtonPayPal extends React.Component{
  constructor(){
    super()
  }

  render() {
    return (
      <PayPalButton
        amount={this.props.amount}
        currency="EUR"
        disableCard='none'
        shippingPreference="NO_SHIPPING"
        style={{color:'blue'}}
        onSuccess={(details, data) => {
          alert(Language('Thank you for your support!'));
        }}
       options={{
          clientId:process.env.REACT_APP_PAYPAl_CLIENTID,
          currency:'EUR',
          disableFunding:'card,sepa,giropay,sofort',
      
        }}
      />
    );
  }
}