import React from 'react';
import Form from 'react-bootstrap/Form';

import { Language } from '../../language/Language'


export default class InputChecked extends React.Component {

    render() {
        return (
            <Form.Control style={{
                color: this.props.isCurrentlyCorrect ? 'green' : 'red',
                fontSize: "2em",
                textAlign: "center",
                fontWeight: this.props.isCorrect ? 'bold' : 'normal'
            }}
                disabled={this.props.isCorrect}
                placeholder={Language(this.props.placeholderEng)}
                ref={this.props.myRef}
                onChange={this.props.handleChange}
                autoCapitalize='none'
                autoComplete="off"
                autoCorrect="off"
            />
        )
    }
}