import AWS from 'aws-sdk'


AWS.config.region = 'eu-central-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-central-1:b277913f-7a04-4a40-97f7-d55b514a84e7',
});

export class TranslateService {
    constructor() {
        this.translate = new AWS.Translate({region: AWS.config.region});
    }

    doTranslate(inputText,sourceLanguageCode,voice, successFullTranslated, multipleTranslationEnded){
        var params = {
            Text: inputText,
            SourceLanguageCode: sourceLanguageCode,
            TargetLanguageCode: voice[0]
        };

        this.translate.translateText(params, function(err, data) {
            if (err) {
                console.log(err, err.stack);
                alert("Error calling Amazon Translate. " + err.message);
                return;
            }
            if (data) {
                successFullTranslated([data.TranslatedText, voice[1], voice[2]], multipleTranslationEnded)
     
            }
        });
        
    }
}