import React from 'react';
import Answer from '../answer/Answer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import FeedBackButton from '../feedBackButton/FeedBackButton'

import { getCurrentBookVocabulary } from '../../data/Data'
import { Language } from '../../language/Language'
import {decryptedData} from '../../encrypter/Encrypter'
import SelectVocabulary from '../selectVocabulary/SelectVocabulary';
import ListGroup from 'react-bootstrap/ListGroup'


class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.loadData = sessionStorage.getItem('dataSelected') ? JSON.parse(sessionStorage.getItem('dataSelected')) : getCurrentBookVocabulary
        this.numberOfQuestion= this.loadData.length
        this.language1to2 = this.props.language1to2;
        let pos = this.language1to2 ? 1 : 0

        this.questionNumber = Math.floor(Math.random() * (this.loadData.length - 1))
        let possibleAnswers = this.getAnswers(this.loadData, this.questionNumber)
        this.rightAnswer = decryptedData(this.loadData[this.questionNumber][pos])
        this.buttonAnswer = this.buttonAnswer.bind(this);
        this.wrongs = 0
        this.state = {
            data: this.loadData,
            answer: '',
            possibleAnswers: possibleAnswers,
            selectVocabulary: false,
            correctAnswerStyle: 
                {rightAnswer:this.rightAnswer,
                rightAnswerFound: false,
                clickedButton:false
            }
        }

    }

    buttonAnswer(answer) {
        let pos = this.language1to2 ? 1 : 0
        this.setState({
            answer: answer,
        });

        if (answer !== this.rightAnswer) {
            this.setState({correctAnswerStyle: 
                {answerClicked:answer,
                rightAnswerClicked: false,
                clicked:true
            }})
            this.wrongs++
        }
        if (answer === this.rightAnswer) {
            this.setState({correctAnswerStyle: 
                {answerClicked:answer,
                rightAnswerClicked: true,
                clicked:true
            }
            })
            setTimeout(()=>{
            this.setState({correctAnswerStyle: 
                    {answerClicked:'',
                    rightAnswerClicked: false,
                clicked:false
                }})
            let newData = this.deleteQuestion(this.state.data, this.questionNumber)
            if (newData.length === 0) {
                this.isDone = Language('Done, ') + this.wrongs + Language(' mistakes.')
                this.setState(state => ({
                    data: []
                }))

            } else {
                this.questionNumber = Math.floor(Math.random() * (this.state.data.length - 1))
                let possibleAnswers = this.getAnswers(newData, this.questionNumber)
                this.rightAnswer = decryptedData(newData[this.questionNumber][pos])
                this.setState({
                    data: newData,
                    possibleAnswers: possibleAnswers
                })
            }

        },1000)}
    }

    getAnswers(data, questionNumber) {
        let pos = this.language1to2 ? 1 : 0
        let possibleAnswerPool = [data[questionNumber][pos]]
        let wrongAnswer
        let wrongAnswersPool = this.loadData.filter(pair => pair[pos] !== possibleAnswerPool[0])

        wrongAnswersPool = this.deleteQuestion(wrongAnswersPool, questionNumber)
        wrongAnswer = Math.floor(Math.random() * (wrongAnswersPool.length - 1))
        possibleAnswerPool.push(wrongAnswersPool[wrongAnswer][pos])

        wrongAnswersPool = this.deleteQuestion(wrongAnswersPool, wrongAnswer)
        wrongAnswer = Math.floor(Math.random() * (wrongAnswersPool.length - 1))
        possibleAnswerPool.push(wrongAnswersPool[wrongAnswer][pos])

        wrongAnswersPool = this.deleteQuestion(wrongAnswersPool, wrongAnswer)
        wrongAnswer = Math.floor(Math.random() * (wrongAnswersPool.length - 1))
        possibleAnswerPool.push(wrongAnswersPool[wrongAnswer][pos])

        return this.shuffle(possibleAnswerPool);
    }

    deleteQuestion(data, question) {
        return data.filter(function (value) { return (value[0] !== data[question][0] && value[1] !== data[question][1]) });
    }

    shuffle(array) {
        let counter = array.length;

        // While there are elements in the array
        while (counter > 0) {
            // Pick a random index
            let index = Math.floor(Math.random() * counter);

            // Decrease counter by 1
            counter--;

            // And swap the last element with it
            let temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }

        return array;
    }

    createAnswers() {
        return (<div>
            <Answer answer={decryptedData(this.state.possibleAnswers[0])} answerClicked={this.buttonAnswer} correctAnswerStyle={this.state.correctAnswerStyle}/>
            <Answer answer={decryptedData(this.state.possibleAnswers[1])} answerClicked={this.buttonAnswer} correctAnswerStyle={this.state.correctAnswerStyle}/>
            <Answer answer={decryptedData(this.state.possibleAnswers[2])} answerClicked={this.buttonAnswer} correctAnswerStyle={this.state.correctAnswerStyle}/>
            <Answer answer={decryptedData(this.state.possibleAnswers[3])} answerClicked={this.buttonAnswer} correctAnswerStyle={this.state.correctAnswerStyle}/>
        </div>
        )
    }

    getQuestions(pos) {
        return (
            <div><Form>
                <Form.Group controlId="translatedText"  >
                    <Form.Label className="mt-4" style={{ fontSize: "2em" }}>{decryptedData(this.state.data[this.questionNumber][pos])}</Form.Label>
                </Form.Group>
            </Form>
                {this.createAnswers()}
            </div>
        )
    }

    selectVocabulary(){
        this.setState({selectVocabulary:!this.state.selectVocabulary})
    }

    returnSelectVocabularyText(){
    return Language('Return to ')+Language('Quiz')
}
    createFeedbackButtonText(){
        let pos = this.language1to2 ? 0 : 1
        if(this.state.data.length>0){
        return Language('Error on')+ ' "' +decryptedData(this.state.data[this.questionNumber][pos])+ '" : "' +' 1: ' + decryptedData(this.state.possibleAnswers[0])+'"' +' 2: ' + decryptedData(this.state.possibleAnswers[1]) +' 3: ' + decryptedData(this.state.possibleAnswers[2])+' 4: ' + decryptedData(this.state.possibleAnswers[3])}
        return ''
}

    render() {
        let pos = this.language1to2 ? 0 : 1
        return (
        <div>
            {
                this.state.selectVocabulary ? <SelectVocabulary currentSite={window.location.href} returnText={this.returnSelectVocabularyText()}/> :
                    <div className="wrapper">
                        {this.state.data.length > 0 ?
                            this.getQuestions(pos)
                            : ''}
                            <Card className='w-75 mr-auto ml-auto mt-3'>
                    <ListGroup  variant="flush">
                        <ListGroup.Item>
                            {this.isDone ? Language('Questions answered: ') + this.numberOfQuestion :Language('Questions: ') + (this.numberOfQuestion - this.state.data.length +1) + ' / '+this.numberOfQuestion
                            }
                            </ListGroup.Item>
                        <ListGroup.Item>{Language('Wrong Answers: ')}  {this.wrongs}</ListGroup.Item>
                    </ListGroup>
                </Card>
                        {this.isDone ?
                            <Button className="m-2" variant="outline-primary" style={{ font: "20" }} onClick={() => window.location.reload(true)}
                            >{Language('Restart')}</Button> : ''}
                            <Button className="m-2" variant="outline-primary" style={{ font: "20" }} onClick={this.selectVocabulary.bind(this)}
                            >{Language('Select Vocabulary')}</Button>
                            {this.state.data.length>0 ?
                            <FeedBackButton message={this.createFeedbackButtonText()}/>
                            : ''}
                    </div>
            }
            </div>
        )
    }
}

export default Quiz;