import AWS from 'aws-sdk'
import { getCurrentBookPollyLanguageCodes } from '../data/Data'
import {Language} from '../language/Language'
import {decryptedData} from '../encrypter/Encrypter'


AWS.config.region = 'eu-central-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL,
});

export class Polly {
    constructor() {
        this.polly = new AWS.Polly();
        this.currentVoice1 = sessionStorage.getItem('Voice1') ? sessionStorage.getItem('Voice1') : this.BasicVoicesDict[getCurrentBookPollyLanguageCodes[0]]
        this.currentVoice2 = sessionStorage.getItem('Voice2') ? sessionStorage.getItem('Voice2') : this.BasicVoicesDict[getCurrentBookPollyLanguageCodes[1]]
        this.params = {
            OutputFormat: 'mp3', /* required */
            Text: 'Hello world', /* required */
            VoiceId: this.currentVoice1,
            TextType: 'text',
        }
        this.audio = new Audio()
        this.audioPlaybackRate = sessionStorage.getItem('globalAudioPlaybackRate') ? sessionStorage.getItem('globalAudioPlaybackRate') : 1

    }

    synthesizeSpeech(currentParams) {
        let audio = this.audio
        let playbackRate = this.audioPlaybackRate
        this.polly.synthesizeSpeech(currentParams, function (err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                var uInt8Array = new Uint8Array(data.AudioStream);
                var arrayBuffer = uInt8Array.buffer;
                var blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
                var url = URL.createObjectURL(blob);
                audio.src = url
                audio.playbackRate = playbackRate
                audio.play()
            }
        });
    }

    say(text, voiceId = this.params.VoiceId) {
        let currentParams = this.params
        currentParams.Text = text
        currentParams.VoiceId = voiceId
        this.synthesizeSpeech(currentParams)
    }

    waitAfterSpeaking(sentence) {
        return 2000 + (sentence.length * 100)
    }

    async sayData(data, voice1params, voice2params, isAudioRunning, audioIsDone) {
        let currentParams = this.params
        currentParams.Text = 'Start'
        this.synthesizeSpeech(currentParams)
        for (let i = 0; i < data.length;) {
            if (isAudioRunning()) {
                await new Promise((resolve) => setTimeout(() => {
                    if (isAudioRunning()) {
                        currentParams.Text = decryptedData(data[i][voice1params.data])
                        currentParams.VoiceId = voice1params.voiceId
                        this.synthesizeSpeech(currentParams)
                    }
                    resolve()
                }, this.waitAfterSpeaking(data[i][voice1params.data])))
            } else {
                audioIsDone()
                break
            }
            if (isAudioRunning()) {
                await new Promise((resolve) => setTimeout(() => {
                    if (isAudioRunning()) {
                        currentParams.Text = decryptedData(data[i][voice2params.data])
                        currentParams.VoiceId = voice2params.voiceId
                        this.synthesizeSpeech(currentParams)
                    }
                    i++;
                    resolve()
                }, this.waitAfterSpeaking(data[i][voice2params.data])))
            }
            else {
                audioIsDone()
                break
            }
        }
        audioIsDone()
    }


    async sayCollection(data, isAudioRunning, audioIsDone, changeCurrentText) {
        let currentParams = this.params
        for (let i = 0; i < data.length;) {
            if (isAudioRunning()) {
                await new Promise((resolve) => setTimeout(() => {
                    if (isAudioRunning()) {
                        currentParams.Text = data[i][0]
                        currentParams.VoiceId = data[i][1]
                        changeCurrentText(data[i])
                        this.synthesizeSpeech(currentParams)
                    }
                    i++
                    resolve()
                }, this.waitAfterSpeaking(data[i][0])))
            } else {
                audioIsDone()
                break
            }
        }
        setTimeout(() => { audioIsDone() }, 3000)
    }

    getAllVoices(success) {
        this.polly.describeVoices({}, function (err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                success(data.Voices.filter(voice => voice.SupportedEngines.includes('standard')))
            }
        })
    }

    removeMultipleLanguage(voicesLanguageCodeIdArray) {
        let unique = []
        let found = ['nb', 'cm']
        for (let i = 0; i < voicesLanguageCodeIdArray.length; i++) {
            if (!found.includes(voicesLanguageCodeIdArray[i][0])) {
                found.push(voicesLanguageCodeIdArray[i][0])
                unique.push(voicesLanguageCodeIdArray[i])
            }
        }
        return unique
    }

    sortFunction(a, b) {
        if (a[1] === b[1]) {
            return 0;
        }
        else {
            return (a[1] < b[1]) ? -1 : 1;
        }
    }

    getLanguageCodeLanguageNameDict(success) {
        let remove = this.removeMultipleLanguage
        let sortf = this.sortFunction
        this.polly.describeVoices({}, function (err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                let dict = remove(data.Voices.filter(voice => voice.SupportedEngines.includes('standard'))
                    .map(voice => [voice.LanguageCode.substring(0, 2), Language(voice.LanguageName.split(' ').slice(-1)[0])]))
                    .sort((a, b) => sortf(a, b))
                success(dict)

            }
        })
    }

    setVoice1(voiceId) {
        this.params.VoiceId = voiceId
        this.currentVoice1 = voiceId
        sessionStorage.setItem('Voice1', voiceId)
    }

    setVoice2(voiceId) {
        this.currentVoice2 = voiceId
        sessionStorage.setItem('Voice2', voiceId)
    }

    setCurrentAudioPlaybackRate(rate) {
        this.audioPlaybackRate = rate
    }

    setGlobalAudioPlaybackRate(rate) {
        this.audioPlaybackRate = rate
        sessionStorage.setItem('globalAudioPlaybackRate', rate)
    }

    sayGreeting(selectedVoice) {
        let language = selectedVoice.LanguageCode.split('-')[0]
        let greetingText = this.Greetings(selectedVoice)[language] ? this.Greetings(selectedVoice)[language] : selectedVoice.Id
        this.say(greetingText, selectedVoice.Id)
    }

    Greetings(selectedVoice) {
        return {
            'en': 'Hello I am ' + selectedVoice.Id + ' and I speak ' + selectedVoice.LanguageName,
            'de': 'Hallo, Ich bin ' + selectedVoice.Id + ' und ich spreche Deutsch',
            'es': 'Hola, yo soy ' + selectedVoice.Id + ' y yo hablo espanol'
        }
    }

    BasicVoicesDict = {
            'arb': 'Zeina',
            'da': 'Naja',
            'nl': 'Lotte',
            'en': 'Joanna',
            'fr': 'Lea',
            'de': 'Marlene',
            'is': 'Dora',
            'it': 'Bianca',
            'ja': 'Mizuki',
            'ko':'Seoyeon',
            'cmn': 'Zhiyu',
            'nb': 'Liv',
            'pl': 'Maja',
            'pt': 'Ines',
            'ro': 'Carmen',
            'ru': 'Tatyana',
            'es': 'Miguel',
            'sv': 'Astrid',
            'tr': 'Filiz',
            'cy': 'Gwyneth'
    }

    setBasicVoices(voiceLanguageCodes){
        this.setVoice1(this.BasicVoicesDict[voiceLanguageCodes[0]])
        this.setVoice2(this.BasicVoicesDict[voiceLanguageCodes[1]])
    }

}