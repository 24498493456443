import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { Language} from '../../language/Language'


import {getCurrentBookName, getCurrentBookDataNames, getCurrentBookSpecificVocabulary, getContentDataLength } from '../../data/Data'


class SelectVocabulary extends React.Component {
    constructor(props) {
        super(props);
        this.alertMessage = ''
        this.dataNames = [...getCurrentBookDataNames]
        this.selectedTypes = sessionStorage.getItem('dataNamesSelected') ? JSON.parse(sessionStorage.getItem('dataNamesSelected')) : getCurrentBookDataNames
        this.state = {
            selectedTypes: this.selectedTypes
        }
        this.selectedBook = getCurrentBookName
    }

    setAlertMessage(){
        this.alertMessage = Language('Please select a data source otherwise the latest deselected data source will remain')
    }

    clearAlertMesage(){
        this.alertMessage = ''
    }

    changed(selected) {
        let index = this.selectedTypes.indexOf(selected)
        if (index === -1) {
            this.selectedTypes.push(selected)
        } else {
            this.selectedTypes.splice(index, 1)
        }
        if (this.selectedTypes.length > 0) {
            this.storeData()
            this.clearAlertMesage()
        } else {
            this.setAlertMessage()
        }
        this.forceUpdate()
    }

    storeData() {
        let dataSelected = getCurrentBookSpecificVocabulary(this.selectedTypes)
        sessionStorage.setItem('dataSelected', JSON.stringify(dataSelected))
        sessionStorage.setItem('dataNamesSelected', JSON.stringify(this.selectedTypes))
    }

    handleDeselectAllClick() {
        this.selectedTypes = []
        this.setState({
            selectedTypes: this.selectedTypes
        })
        this.setAlertMessage()

    }

    handleSelectAllClick() {
        this.selectedTypes = this.dataNames
        this.setState({
            selectedTypes: this.selectedTypes
        })
        this.clearAlertMesage()
        this.storeData()
    }

    render() {
        return (
            <div className="wrapper"> 
                <h2 className='mt-2'>{getCurrentBookName}</h2>
                {this.props.currentSite ? (<div>
                <Button className="mb-2 mr-2" variant="outline-primary" style={{ font: "20" }} onClick={()=>window.location.href=this.props.currentSite}
                    onMouseDown={(e) => e.preventDefault()}>{this.props.returnText}</Button> </div>) : ''}
                <Button className="mb-2 mr-2" variant="outline-primary" style={{ font: "20" }} onClick={this.handleDeselectAllClick.bind(this)}
                    onMouseDown={(e) => e.preventDefault()}>{Language('Deselect All')}</Button>
                <Button className="mb-2" variant="outline-primary" style={{ font: "20" }} onClick={this.handleSelectAllClick.bind(this)}
                    onMouseDown={(e) => e.preventDefault()}>{Language('Select All')}</Button>
                {this.alertMessage ?
                    <Alert variant='danger'>{this.alertMessage}</Alert>
                    : ''}
                <Form>
                    {this.dataNames.map((name) => (
                        <div key={name} className="mb-2 ml-2 mr-2 list-group-item d-flex justify-content-between">
                            <Form.Check
                                type='checkbox'
                                id={name}
                                label={name + ' ( ' + getContentDataLength(name) + ' )'}
                                checked={this.state.selectedTypes.indexOf(name) > -1}
                                onChange={e => this.changed(name)}
                            />
                        </div>
                    ))}
                </Form>
            </div>

        )
    }
}

export default SelectVocabulary;

