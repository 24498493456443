import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Language } from '../../language/Language'
import { Form } from 'react-bootstrap';


export default function FeedBackButton(props) {

  const [show, setShow] = useState(false);
  const [commentText, setCommentText] = useState('');

  function sendEmail(e) {
    e.preventDefault();
    setShow(false)
    emailjs.send(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, {
      message: props.message,
      comment: commentText
    }, process.env.REACT_APP_YOUR_USER_ID)
      .then((result) => {
      }, (error) => {
      });
  }


  function handleChange(e) {
    setCommentText(e.target.value)
  }

  return (
    <div className='mb-3'>
      {show ?
        <Card>
          <Card.Header>{Language('Report spelling error')}</Card.Header>
          <Card.Body>
            <Card.Text>
              <Form.Control className='mt-3' style={{ fontSize: "1em" }} as="textarea" placeholder={Language('Please tell me what you think is wrong.')} maxLength="120" autoFocus onChange={(e) => handleChange(e)}
                autoCapitalize='none'
                autoComplete="off"
                autoCorrect="off"
              />
            </Card.Text>
            <Button variant='warning' className='m-3' onClick={(e) => sendEmail(e)}>{Language('Send Feedback')}</Button>
            <Button variant='outline-primary' onClick={() => setShow(false)}>{Language('Cancel')}</Button>
          </Card.Body>
        </Card> : <Button variant='warning' style={{ fontSize: "0.7em", color: 'white' }} onClick={() => setShow(true)}>{Language('Report spelling error')}</Button>}
    </div>
  );
}