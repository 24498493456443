import React from 'react';
import Button from 'react-bootstrap/Button';


function mouseDown(e) {
    document.activeElement.blur()
}

function Answer(props) {
    let styleFormat ={}
    if (props.correctAnswerStyle.clicked === true){
    if (props.correctAnswerStyle.rightAnswerClicked===true && props.answer===props.correctAnswerStyle.answerClicked){
        styleFormat = {
            borderColor:'green',
            borderWidth:'3px',
            fontWeight:'bold'
    }}
    if (props.correctAnswerStyle.answerClicked===props.answer && props.correctAnswerStyle.clicked && props.correctAnswerStyle.rightAnswerClicked===false){
        styleFormat = {
            borderColor:'red',
            borderWidth:'3px',
    }
    }}

    return (
        <Button className=" ml-2 mr-2 mt-2 answerbutton" variant="outline-primary unmarked" style={{ font: "20" }}
            onClick={() => { props.answerClicked(props.answer) }}
            onMouseDown={(e) => mouseDown(e)}
            onMouseUp={(e) => mouseDown(e)}
            style={styleFormat}
            disabled={props.correctAnswerStyle.rightAnswerClicked}
            >
            {props.answer}
        </Button>
    )
}

export default Answer;