import DataHandler from '../dataHandler/DataHandler'
import {Polly} from '../audioPolly/Polly'
import {encryptData} from '../encrypter/Encrypter'
let intialData = require('./jsonData/English German Class 5.json');



function loadBookNames() {
    const success = (data) => {
        setAllBookNames(data)
    }
    let dataHandler = new DataHandler()
    dataHandler.getAllBookNames(success)
}

function loadBook(bookName) {
    const success = (data) => {
        encodeData(data)
        setCurrentBook(data)
        let polly = new Polly()
        polly.setBasicVoices(data.pollyLanguageCode)
    }
    let dataHandler = new DataHandler()
    dataHandler.getSpecificBook(bookName, success)
}

function encodeData(data){
    for (let i =0; i<data.content.length; i++){
        for (let j = 0; j <data.content[i].data.length; j++){
            data.content[i].data[j][0] = encryptData(data.content[i].data[j][0])
            data.content[i].data[j][1] = encryptData(data.content[i].data[j][1])
        }
    }
}





function setAllBookNames(bookNames) {
    sessionStorage.setItem('bookNames', bookNames)
}

function getAllBookNames() {
    return sessionStorage.getItem('bookNames').split(",");
}

function setCurrentBook(book) {
    sessionStorage.setItem('currentBook', JSON.stringify(book))
}

function getCurrentBook() {
    return  JSON.parse(sessionStorage.getItem('currentBook')) ? JSON.parse(sessionStorage.getItem('currentBook')) : intialData
}


let currentBook = getCurrentBook() 

const getCurrentBookPollyLanguageCodes = currentBook.pollyLanguageCode

const getCurrentBookName = currentBook.bookName

const getCurrentBookDataNames = currentBook.content.map(content => content.dataName)

const getCurrentBookVocabulary = currentBook.content.map(content => content.data)
    .reduce((prev, next) => { return prev.concat(next) })

const getCurrentBookSpecificVocabulary = (filter) => currentBook.content.filter(content =>
    filter.indexOf(content.dataName)>=0)
    .map(content => content.data)
    .reduce((prev, next) => { return prev.concat(next) })

const getSingleCurrentBookSpecificVocabulary = (filter) => currentBook.content.filter(content =>
        filter===content.dataName)
        .map(content => content.data)[0]

const getContentDataLength = (dataName) => {
    return currentBook.content.filter(content => (content.dataName === dataName)).map(content => content.data.length)
}


const languageCodeDict = { 'en': 'English', 'de': 'German', 'es': 'Spanish' }

function getLanguage1() {
    return languageCodeDict[getCurrentBookPollyLanguageCodes[0]]
}

function getLanguage2() {
    return languageCodeDict[getCurrentBookPollyLanguageCodes[1]]
}

export {
    getLanguage1,
    getLanguage2,
    getCurrentBookPollyLanguageCodes,
    getCurrentBookName,
    getCurrentBook,
    getCurrentBookVocabulary,
    getCurrentBookSpecificVocabulary,
    getSingleCurrentBookSpecificVocabulary,
    getContentDataLength,
    getCurrentBookDataNames,
    getAllBookNames,
    loadBookNames,
    loadBook,
    setCurrentBook
}

