import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OutputFeedback from '../outputFeedback/OutputFeedback'
import RestartHelp from '../restartHelp/RestartHelp'
import InputChecked from '../inputChecked/InputChecked'
import SelectVocabulary from '../selectVocabulary/SelectVocabulary';
import FeedBackButton from '../feedBackButton/FeedBackButton'

import { Polly } from '../../audioPolly/Polly'
import { Language } from '../../language/Language'
import { getCurrentBookVocabulary } from '../../data/Data'
import {decryptedData} from '../../encrypter/Encrypter'


class ListeningChallenge extends React.Component {
    constructor(props) {
        super(props);
        let loadData = sessionStorage.getItem('dataSelected') ? JSON.parse(sessionStorage.getItem('dataSelected')) : getCurrentBookVocabulary
        this.state = {
            data: loadData,
            selectVocabulary: false
        }
        this.Polly = new Polly()
        this.inputField = ''
        this.isCurrentlyCorrect = true
        this.amountTotalWords= this.state.data.length
        this.isCorrect = false
        this.englishGerman = false;
        this.questionNumber = Math.floor(Math.random() * (this.state.data.length - 1))
        this.amountCorrectWords = 0
        this.amountWrongCharacters = 0
        this.amountHelpUsed = 0
        this.helpUsed = false
        this.isDone = false
        this.startReplayButtonText = Language('Start')
    }

    componentDidMount() {
        this.inputText.focus();
    }

    componentDidUpdate() {
        if (!this.state.selectVocabulary && this.state.data.length>0) {
            this.inputText.focus();
        }
    }

    deleteQuestion(data, question) {
        return data.filter(function (value) { return value[0] !== data[question][0] });
    }

    handleChange(event) {
        let isCorrectSubstring = event.target.value === decryptedData(this.state.data[this.questionNumber][0]).substring(0, event.target.value.length)
        if (isCorrectSubstring) {
            this.isCurrentlyCorrect = true
            if (this.state.data.length > 1) {
                if (event.target.value === decryptedData(this.state.data[this.questionNumber][0])) {
                    this.isCorrect = true
                    setTimeout(() => {
                        this.setState(state => ({
                            data: this.deleteQuestion(this.state.data, this.questionNumber)
                        }), () => {
                            this.questionNumber = Math.floor(Math.random() * (this.state.data.length - 1))
                            this.inputText.value = '';
                            this.helpText = ''
                            this.isCorrect = false
                            this.helpUsed = false
                            this.amountCorrectWords++
                            this.Polly.say(decryptedData(this.state.data[this.questionNumber][0]))
                            this.forceUpdate()
                        })
                    }, 750)
                }
            } else {
                if (event.target.value === decryptedData(this.state.data[this.questionNumber][0])) { 
                    this.isDone = true 
                    this.setState({data:[]})}
            }
            this.forceUpdate();
        } else {
            if (this.isCurrentlyCorrect) { this.amountWrongCharacters++ }
            this.isCurrentlyCorrect = false
            this.forceUpdate();
        }
    }

    handleHelpClick() {
        this.helpText = '( ' + decryptedData(this.state.data[this.questionNumber][0]) + ' )'
        if (!this.helpUsed) { this.amountHelpUsed++ }
        this.helpUsed = true
        this.forceUpdate()
    }

    handleRefreshSoundClick() {
        this.startReplayButtonText = Language('Replay')
        this.forceUpdate()
        this.Polly.say(decryptedData(this.state.data[this.questionNumber][0]))
    }

    selectVocabulary() {
        this.setState({ selectVocabulary: !this.state.selectVocabulary })
    }

    returnSelectVocabularyText() {
        return Language('Return to ') + Language('Listen and Write')
    }

    createFeedbackButtonText() {
        if (this.state.data.length > 0) {
            return Language('Error on') + ' "' + (decryptedData(this.state.data[this.questionNumber][0]) + '" : "') + decryptedData(this.state.data[this.questionNumber][1]) + '"'
        }
        return ''
    }

    render() {
        return (
            <div>
                {
                    this.state.selectVocabulary ? <SelectVocabulary currentSite={window.location.href} returnText={this.returnSelectVocabularyText()} /> :
                        <div className="wrapper">
                            {this.isDone ? <h1> {Language('!! Finished !!')}</h1> :
                                <Form>
                                    <Form.Group controlId="translatedText"  >
                                        <Button className="m-1 mt-2" variant="outline-primary unmarked" style={{ font: "20" }} onClick={this.handleRefreshSoundClick.bind(this)}
                                            onMouseDown={(e) => e.preventDefault()}>{this.startReplayButtonText}</Button>
                                        {this.props.withGerman ? <h2 className="mt-1">{decryptedData(this.state.data[this.questionNumber][1])}</h2> : ''}
                                        <h3 className="mb-2">{this.helpText}</h3>
                                        <InputChecked
                                            isCurrentlyCorrect={this.isCurrentlyCorrect}
                                            isCorrect={this.isCorrect}
                                            placeholderEng='listen and type'
                                            handleChange={this.handleChange.bind(this)}
                                            myRef={ref => this.inputText = ref}
                                        />
                                    </Form.Group>
                                </Form>
                            }
                            <OutputFeedback amountCorrectWords={this.amountCorrectWords} amountTotalWords={this.amountTotalWords} amountWrongCharacters={this.amountWrongCharacters} amountHelpUsed={this.amountHelpUsed} />
                            <RestartHelp isDone={this.isDone} handleHelpClick={this.handleHelpClick.bind(this)} />
                            <Button className="m-2" variant="outline-primary" style={{ font: "20" }} onClick={this.selectVocabulary.bind(this)}
                            >{Language('Select Vocabulary')}</Button>
                             {this.state.data.length>0 ?
                            <FeedBackButton message={this.createFeedbackButtonText()} /> : ''}
                        </div>}
            </div>
        )
    }
}

export default ListeningChallenge;