import React from 'react';
import logo from '../../logo/logo.png'

import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {getLanguage1, getLanguage2, loadBookNames, getCurrentBookName} from '../../data/Data'
import { Language } from '../../language/Language'
import SelectBookOption from '../selectBookOption/SelectBookOption';



class Home extends React.Component {
    constructor(props) {
        super(props)
        this.StartPageText = {
            WELCOME: Language('Welcome to ReTraMa, this page was designed to help you to improve your language skills through Quizes, Translation, Hearing and Writing. Right now its absolutely free.'),
            QUIZ: Language('Quiz'),
            QUIZTEXT: Language('In this section you can try out your vocabulary knowledge by doing a German to English or English to German Quiz.'),
            VOICE1VOICE2: Language(getLanguage1()) +' -> '+ Language(getLanguage2()),
            VOICE2VOICE1: Language(getLanguage2()) +' -> '+ Language(getLanguage1()),
            TYPING: Language('Typing'),
            TYPINGTEXT: Language('In this section you have to translate the given input. The first option gives you the plain german text as input. The second option speaks the english text you have to write. The third option gives you the german text and speaks the english solution.'),
            HEARVOCABULARY: Language('Listen to selected vocabulary'),
            HEARVOCABULARYTEXT: Language('In this section you can choose selected vocabulary and listen to the translation.'),
            FEATURE: Language('Special Feature'),
            FEATURETEXT: Language('In the section I provide cool features. You can let polly speak what you want and choose her voice. Another feature is that you can put in text in one of 18 languages and get the translation and the speach output in these languages'),
            SUPPORT: Language('Support ReTraMa'),
            SUPPORTTEXT: Language('If you want to support this project you can help me with your Feedback or financially through Paypal')
        }
    }


    changeNavWhere(position) {
        sessionStorage.setItem('currentPosition', position)
    }

    render() {
        return (<div>
            <Image className="mt-2" src={logo} width="60%" fluid />
            <div >
                <Card body>{this.StartPageText.WELCOME}</Card>
                <Card >
                    <Card.Header>{this.StartPageText.SUPPORT}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.StartPageText.SUPPORTTEXT}
                        </Card.Text>
                        <Button variant="outline-primary" className='m-1 link' href='mailto:christian.sonntag@hotmail.com?subject=Feedback ReTraMa' > {Language('Send Feedback via Email')}</Button>
                        <Button variant="outline-primary" className='m-1' href="/projectSupport" onClick={() => this.changeNavWhere(Language('Support ReTraMa'))} >{Language('Support ReTraMa through Paypal')}</Button>
                    </Card.Body>
                </Card>
                <Card >
                    <Card.Header>{Language('Current Book: ') + getCurrentBookName}</Card.Header>
                    <Card.Body>
                    <Button variant="outline-primary" className='m-1' href="/selectBook" onClick={() => this.changeNavWhere(Language('Select a Book'))} >{Language('Select Book another book')}</Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>{this.StartPageText.QUIZ}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.StartPageText.QUIZTEXT}
                        </Card.Text>
                        <Button variant="outline-primary" className='m-1' href="/quiz1" onClick={() => this.changeNavWhere('Quiz '+this.StartPageText.VOICE1VOICE2)} >{this.StartPageText.VOICE1VOICE2}</Button>
                        <Button variant="outline-primary" className='m-1' href="/quiz2" onClick={() => this.changeNavWhere('Quiz '+this.StartPageText.VOICE2VOICE1)} >{this.StartPageText.VOICE2VOICE1}</Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>{this.StartPageText.TYPING}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.StartPageText.TYPINGTEXT}
                        </Card.Text>
                        <Button variant="outline-primary" className='m-1' href="/typingChallenge" onClick={() => this.changeNavWhere(Language('Translate into ')+ getLanguage1())} >{Language('Translate into ')+ Language(getLanguage1())}</Button>
                        <Button variant="outline-primary" className='m-1' href="/listeningChallenge" onClick={() => this.changeNavWhere(Language('Listen and Write'))} >{Language('Listen and Write')}</Button>
                        <Button variant="outline-primary" className='m-1' href="/listeningChallengeWithGerman" onClick={() => this.changeNavWhere(Language('Listen with text in ')+ Language(getLanguage2()))} >{Language('Listen with text in ')+ Language(getLanguage2())}</Button>
                    </Card.Body>
                </Card>
                <Card >
                    <Card.Header>{this.StartPageText.HEARVOCABULARY}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.StartPageText.HEARVOCABULARYTEXT}
                        </Card.Text>
                        <Button variant="outline-primary" className='m-1' href="/listenVocabulay" onClick={() => this.changeNavWhere(Language('Listen to vocabulary'))} >{Language('Listen to vocabulary')}</Button>
                    </Card.Body>
                </Card>
                <Card >
                    <Card.Header>{this.StartPageText.FEATURE}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.StartPageText.FEATURETEXT}
                        </Card.Text>
                        <Button variant="outline-primary" className='m-1' href="/letPollySpeak" onClick={() => this.changeNavWhere(Language('let Polly speak'))} >{Language('let Polly speak')}</Button>
                        <Button variant="outline-primary" className='m-1' href="/translateAndSpeak" onClick={() => this.changeNavWhere(Language('Translate and Speak'))} >{Language('Translate and Speak')}</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
        )
    }

}

export default Home;