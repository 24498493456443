import React from 'react';
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

import { Language } from '../../language/Language'


export default class OutputFeedback extends React.Component {
  
    render() {
        return (
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item>{Language('right Words: ')} {this.props.amountCorrectWords}  / {this.props.amountTotalWords} </ListGroup.Item>
                        <ListGroup.Item>{Language('typing Errors: ')}  {this.props.amountWrongCharacters}</ListGroup.Item>
                        <ListGroup.Item>{Language('used Helps: ')} {this.props.amountHelpUsed}</ListGroup.Item>
                    </ListGroup>
                </Card>
        );
    }
}