import React from 'react';
import Button from 'react-bootstrap/Button';

import { Language} from '../../language/Language'


export default class RestartHelp extends React.Component {

    render() {
        return (
            <div>
                {this.props.isDone ?
                    <Button className="mb-3" variant="outline-primary unmarked" style={{ font: "20" }} onClick={() => window.location.reload(true)}>{Language('Restart')}</Button>
                    :
                    <Button className="mb-3" variant="outline-primary unmarked" style={{ font: "20" }} onClick={this.props.handleHelpClick}
                        onMouseDown={(e) => e.preventDefault()}>{Language('Help')}</Button>
                }
            </div>
        )
    }
}